import { mapState } from 'vuex'

export default {
    data() {
      return {
        //   prevHeight: 0
      }
    },
    computed: {
        ...mapState([
            'userUiElements',
        ]),
        },
    methods: {
        getComponentOperationType(componentId) {
            const elements = this.userUiElements[componentId];
            if (!elements || !elements.length) {
                return;
            }
            const requestMethod = elements[0]?.resource_method;
            switch (requestMethod) {
                case 'get':
                    return 'view';
                case 'put':
                    return 'edit';
                case 'post':
                    return 'create';
                default:
                    return null;
            }
        },
        getComponentSettings(component) {
          return component?.settings || {};
          },
        delay(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        handleScrollable(source = null) {
          const tableWrappers = this.getTableWrapper(source);

          if (tableWrappers.length > 0) {
              for (let i = 0; i < tableWrappers.length; i++) {
                  let wrapper = tableWrappers[i];
                  if (!wrapper) {
                      continue;
                  }
                  let shadowLeft = wrapper.querySelector(".js-shadow-left");
                  let shadowRight = wrapper.querySelector(".js-shadow-right");

                  // console.log('wrapper.scrollWidth', wrapper.scrollWidth, ', wrapper.clientWidth', wrapper.clientWidth, ', wrapper.scrollLeft', wrapper.scrollLeft)

                  if (wrapper.scrollWidth > wrapper.clientWidth) {
                      if (wrapper.scrollLeft === 0) {
                          shadowLeft.style.opacity = "0";
                          shadowRight.style.opacity = "1";
                      } else if (wrapper.scrollLeft + wrapper.clientWidth >= wrapper.scrollWidth - 1) {
                          shadowLeft.style.opacity = "1";
                          shadowRight.style.opacity = "0";
                      } else {
                          shadowLeft.style.opacity = "1";
                          shadowRight.style.opacity = "1";
                      }
                  } else {
                      shadowLeft.style.opacity = "0";
                      shadowRight.style.opacity = "0";
                  }
              }
          }
        },     
        getTableWrapper(source = null) {
          const tableWrapperId = 'data-table-wrapper-' + this.componentData?.id + (source ? `-${source}` : '');
          let tableWrappers;

          //   console.log('handleScrollable: tableWrapperId:', tableWrapperId)
  
            if (tableWrapperId && typeof tableWrapperId === "string" && tableWrapperId.includes("data-table-wrapper")) {
                tableWrappers = [document.getElementById(tableWrapperId)];
              //   console.log('handleScrollable: tableWrappers getElementById:', tableWrappers)
            } else {
                tableWrappers = document.querySelectorAll(".js-data-table-wrapper");
              //   console.log('handleScrollable: tableWrappers querySelectorAll:', tableWrappers)
            }

            return tableWrappers;
        },
        handleReorder(event, subsetUserUiElements) {
          const { newIndex, oldIndex } = event.moved;
          const componentId = this.componentData?.id;
          const allUserUiElementsInComponent = this.userUiElements[componentId] || [];
          let updatedUserUiElements = [...subsetUserUiElements];
          let groupTypeElements = [];
        
          if (allUserUiElementsInComponent.length !== subsetUserUiElements.length) {
            groupTypeElements = allUserUiElementsInComponent.filter(element => element.parent_id !== null) || [];
          }
        
          // Move the item from oldIndex to newIndex
          const [movedItem] = updatedUserUiElements.splice(oldIndex, 1);
          updatedUserUiElements.splice(newIndex, 0, movedItem);
        
          // Save optimistically the new order of the elements
          this.$store.commit('setUserUiElements', {
            componentId,
            data: [...updatedUserUiElements, ...groupTypeElements]
          });
        
          // Send the new order to the server
          this.$store.dispatch('updateUiElement', { position: newIndex + 1 });
        }        
      
      //   getTableWrapperWidth() {
      //     const tableWrappers = this.getTableWrapper();
      //     if (tableWrappers.length > 0) {
      //         // Assuming there is always at least one wrapper, we take the first one's width.
      //         const width = tableWrappers[0].clientWidth;
      //         console.log('Width of the table wrapper:', width); // Optional: for debugging
      //         return width;
      //     } else {
      //         console.error('No table wrapper found.');
      //         return 0; // Return 0 if no wrapper is found
      //     }
      // }
      
         
    }
  }
  