<template>
  <!-- <pre>userUiElements: {{ userUiElements }}</pre> -->
  <!-- <pre>userUiComponents: {{ userUiComponents }}</pre> -->
  <!-- <pre>componentOperationType: {{ componentOperationType }}</pre> -->
  <div
    class="page-content" 
    :style="pageContentStyles" 
    @dragover="handleDragOver" 
    @drop="handleDrop"
    @dragenter="handleDragEnter" 
    @dragleave="handleDragLeave"
  >
  <div v-if="appPages?.length === 0" class="page-content__empty">
      <h2 class="page-content__empty-title">No pages yet</h2>
      <p>Create a new page in the navigation panel to start building your app.</p>
  </div>
  <div v-else-if="userUiComponents?.length === 0" class="page-content__empty">
      <h2 class="page-content__empty-title">No components yet</h2>
      <p>Drag here UI components from the right panel to build your page.</p>
  </div>


    <Card v-if="Array.isArray(userUiComponents)" />
    <Notification />

    <ModalDataShell v-if="modalComponentData" @closeModal="closeModal" :component-data="modalComponentData" />

    <ModalConfirmAction 
    v-if="showModalConfirmAction" 
    :modal-content=" {
       'title': 'Discard ' + (componentOperationType === 'create' ?  'new entry' : 'changes') +  '?',
        // 'text': 'Are you sure you want to delete this entry?',
        'confirmText': 'Discard',
        'cancelText': 'Go back'
    }" 
    @confirm-action="(answer) => confirmAction(answer)" 
/>



  </div>



</template>

<script>
import { mapState, mapGetters } from 'vuex';
import GenericMixin from '@/mixins/genericMixin.js'
import { defineAsyncComponent } from 'vue';

const Card = defineAsyncComponent(() => import('@/components/PageContent/Card.vue'));
const Notification = defineAsyncComponent(() => import('@/components/PageContent/Notification.vue'));
const ModalDataShell = defineAsyncComponent(() => import('@/components/PageContent/modals/DataShellModal.vue'));
const ModalConfirmAction = defineAsyncComponent(() => import('@/components/PageContent/modals/ConfirmActionModal.vue'));

export default {
  name: 'PageContent',
  mixins: [GenericMixin],
  components: {
    Card,
    Notification,
    ModalDataShell,
    ModalConfirmAction
  },
  data() {
    return {
      showModalConfirmAction: false,
    }
  },
  computed: {
    ...mapGetters(['selectedPageId']),
    ...mapState([
      'userUiComponents',
      'selectedUiComponentId',
      'modalComponentData',
      'formData',
      'appPages',
    ]),
    pageContentStyles() {
      const styles = {};
      if (this.$store.state.navWidth) {
        const navWidth = this.$store.state.navWidth;
        styles.marginLeft = `${navWidth}px`;
        styles.width = `calc(100% - ${navWidth}px`;
        styles.width += this.$store.state.editMode ? ' - 264px)' : ')';
      }
      return styles;
    },
    componentOperationType() {
      return this.getComponentOperationType(this.modalComponentData?.id); // calling for GenericMixin
    }
  },
  watch: {
    selectedPageId(newValue, oldValue) {
      if (newValue !== oldValue && newValue !== null) {
        // console.log('selectedPageId changed from: ', oldValue, ' to: ', newValue)
        this.$store.dispatch('fetchUserUiComponents');
        
        // Clear all selected values in the table select on page change
        this.$store.commit('clearFullTableSelectValues');
      }
    },
  },
  methods: {
    confirmAction(answer) {
      if(answer) {
        this.clearModalData();
        this.showModalConfirmAction = false;
      } else {
        this.showModalConfirmAction = false;
      }
    },
    closeModal() {
          if(this.modalComponentData?.type === 'form-item' && Object.keys(this.formData).length) this.showModalConfirmAction = true;
          else this.clearModalData();
    },
    clearModalData() {
      if (this.modalComponentData && this.modalComponentData.type === 'form-item') {
        this.$store.dispatch('clearFormNewValuesTableSelect', this.modalComponentData.id);
      }
      this.$store.commit('setModalComponentData', null);
    },
    handleDragEnter(event) {
        event.preventDefault();
    },
    handleDragLeave(event) {
        event.preventDefault();
    },
    handleDragOver(event) {
      event.preventDefault();
      event.stopPropagation();
      const dataTypes = event.dataTransfer.types;
      if (dataTypes.includes('element-type')) {
        event.dataTransfer.dropEffect = 'none';
      }
    },
    handleDrop(event) {
      event.stopPropagation();
      event.preventDefault();
      const index = event.dataTransfer.getData('text/plain');
      event.dataTransfer.clearData();
      this.$store.dispatch('addUiComponentToPage', index);
    },
    handleOutsideClick(event) {
      if (!this.$store.state.editMode) {
        return;
      }

      const selectedCardClassName = 'js-card-selected';
      const selectedElement = document.querySelector('.' + selectedCardClassName);

      if (selectedElement) {
        // const rightSidebar = event.target.closest('.js-right-sidebar__section--selected-component');
        const sectionSelectedComponent = event.target.closest('.js-right-sidebar');
        const modal = event.target.closest('.modal');
        if (!selectedElement.contains(event.target) && !sectionSelectedComponent && !modal) {
          this.$store.commit('setSelectedUiComponentId', null);
          document.removeEventListener('keydown', this.handleDeleteKeyDown);
        }
      }
    },
  },
  created() {
    if (this.selectedPageId) {
      this.$store.dispatch('fetchUserUiComponents');
    }
  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
  },
  updated() {
    // document.removeEventListener('keydown', this.handleDeleteKeyDown);
    if (!this.$store.state.editMode) {
      this.$store.commit('setSelectedUiComponentId', null);
    }

  },
}
</script>

<style>

</style>