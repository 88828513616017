<template>
    <div class="header">
        <img src="@/assets/img/logo.svg" class="header__logo" v-once />
        <ul class="tabs">
            <li :class="viewTabClass" @click="updateEditMode(false)">View</li>
            <li :class="buildTabClass" @click="updateEditMode(true)">Build</li>
        </ul>

       <LoginAs v-if="me?.role === 'admin'" />

        <button class="btn btn-large btn-text-primary" @click="handleClick">
            <span>Logout</span>
        </button>
    </div>
</template>

<script>

import { mapState } from 'vuex';
import logoutMixin from '@/mixins/logoutMixin.js';
import LoginAs from '@/components/Header/LoginAs.vue';

export default {
    name: 'Header',
    mixins: [logoutMixin],
    components: {
        LoginAs
    },
    data() {
        return {
            timeoutId: null
        }
    },
    watch: {
        appPages() {
            if(this.appPages?.length === 0) {
                this.updateEditMode(true);
            }
        }
    },
    computed: {
        ...mapState(['editMode', 'me', 'appPages']),
        viewTabClass() {
            return !this.editMode ? 'tabs__item--active': 'tabs__item';
        },
        buildTabClass() {
            return this.editMode ? 'tabs__item--active': 'tabs__item';
        },
    },
    methods: {
        updateEditMode(value) {
            this.$store.commit('updateEditMode', value);
        },
        handleClick() {
            this.timeoutId = setTimeout(() => {
                this.handleLogout() // call logout mixin
            }, 350)
        }
    },
    beforeUnmount() {
        clearTimeout(this.timeoutId);
    }
}

</script>

<style>
/* .tabs__item--active {
  transition: all 0.3s ease-in-out;
  transform: translateX(-100%);
}
.tabs__item--active {
  transform: translateX(100%);
} */
</style>