<template>
    <div class="right-sidebar__section-item">
        <div class="right-sidebar__section-subtitle">Attributes</div>

        <div class="textfield textfield-large textfield-filled textfield-search">
            <input v-model="searchTerm" @click="selectInputText($event)" placeholder="Search" type="text">
        </div>

        <SidebarSection 
            :properties="infoPropertiesNotInComponent"
            :selectedProperties="selectedProperties"
            :propertiesInResource="propertiesInResource"
            :searchTerm="searchTerm"
            title="Properties"
            emptyMessage="All properties in use."
            @dragStart="handleDragStart"
            @click="handleClick"
            @doubleClick="handleDoubleClick"
            @getClassForProperty="(property) => getClassForProperty(property)"
        />
    
        <SidebarSection 
            v-if="filterProperties.length"
            :properties="filterPropertiesNotInComponent"
            :selectedProperties="selectedProperties"
            :searchTerm="searchTerm"
            title="Filters"
            emptyMessage="All filters in use."
            @dragStart="handleDragStart"
            @click="handleClick"
            @doubleClick="handleDoubleClick"
            @getClassForProperty="(property) => getClassForProperty(property)"
        />

        <SidebarSection 
            v-if="paginationProperties.length"
            :properties="paginationPropertiesNotInComponent"
            :selectedProperties="selectedProperties"
            :searchTerm="searchTerm"
            title="Pagination"
            emptyMessage="All pagination controls in use."
            @dragStart="handleDragStart"
            @click="handleClick"
            @doubleClick="handleDoubleClick"
            @getClassForProperty="(property) => getClassForProperty(property)"
        />

</div>

</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import SidebarSection from '@/components/RightSidebar/sections/subsections/components/SidebarSection.vue';

const RESOURCES_PROPERTIES_URL = '/properties.php'

export default {
    name: 'userApiProperties',
    components: { SidebarSection },
    data() {
        return {
            searchTerm: '',
            selectedProperties: [],
            isDoubleClick: false,
            lastSelectedProperty: null
        }
    },
    computed: {
        ...mapState([
            'userApiProperties', 
            'selectedUserApiResource', 
            'selectedApiService', 
            'selectedUiComponentId',
            'userUiComponents',
            'userUiElements'
        ]),
        propsAlreadyInComponent() {
            return this.userUiElements[this.selectedUiComponentId];
        },
        propertiesNotInComponent() {
            return [
                ...this.infoPropertiesNotInComponent,
                ...this.filterPropertiesNotInComponent,
                ...this.paginationPropertiesNotInComponent
            ];
        },
        selectedUiComponent() {
            return this.userUiComponents.find(item => item.id === this.selectedUiComponentId);
        },
        propertiesInResource() {
            return this.userApiProperties?.filter(item => item.purpose === 'item_info') || [];
        },
        infoProperties() {
            return this.userApiProperties?.filter(item => item.purpose === 'item_info' && item.operation !== 'group' && this.isSearchMatch(item)) || [];
        },
        infoPropertiesNotInComponent() {
            return this.filterPropertiesNotInComponentHelper('item_info', this.infoProperties);
        },
        filterProperties() {
            return this.userApiProperties?.filter(item => item.purpose === 'filter') || [];
        },
        filterPropertiesNotInComponent() {
            return this.filterPropertiesNotInComponentHelper('filter', this.filterProperties);
        },
        paginationProperties() {
            return this.userApiProperties?.filter(item => item.purpose === 'pagination') || [];
        },
        paginationPropertiesNotInComponent() {
            return this.filterPropertiesNotInComponentHelper('pagination', this.paginationProperties);
        },
    },
    watch: {
        selectedUserApiResource: {
            immediate: true,
            deep: true,
            handler: 'fetchUserApiPropertiesIfNeeded'
        },
        selectedApiService: {
            immediate: true,
            deep: true,
            handler: 'fetchUserApiPropertiesIfNeeded'
        },
        selectedUiComponentId() {
            this.searchTerm = '';
        },
        userUiElements: {
            handler() {
                this.selectedProperties = [];
            },
            deep: true
        }
    },
    methods: {
        selectInputText(event) {
            event.target.select();
        },
        handleDragStart(property, event) {
            this.updateSelectedProperties(property);
            event.dataTransfer.setData('text/plain', JSON.stringify(this.selectedProperties));
            event.dataTransfer.setData('element-type', 'api-property');
            this.setDragImage(event);
        },
        validateDragTarget(event) {
            const isCard = event.target.closest('.js-card-selected');
            if (!isCard) {
                event.preventDefault();
                event.dataTransfer.dropEffect = 'none';
            }
        },
        handleDragOver(event) {
            this.validateDragTarget(event);
        },
        handleDrop(event) {
            this.validateDragTarget(event);
        },
        handleClick(property, event) {
            if (this.isDoubleClick) return; 

            const lastSelectedIndex = this.lastSelectedProperty 
                ? this.propertiesNotInComponent.findIndex(p => p.id === this.lastSelectedProperty.id) 
                : null;

            const currentIndex = this.propertiesNotInComponent.findIndex(p => p.id === property.id);

            if (event?.shiftKey && lastSelectedIndex !== null) {
                this.selectRange(lastSelectedIndex, currentIndex);
            } else {
                this.toggleSelection(property);
            }

            this.lastSelectedProperty = property;
        },
        handleDoubleClick(property) {
            this.isDoubleClick = true;
            this.$store.dispatch('addUserUiElementToCard', [property]);
            this.isDoubleClick = false;
        },
        handleClickOutside(event) {
            const isInsideContainer = document.querySelector('.draggable-attribute--container').contains(event.target);
            if (!isInsideContainer) {
                this.clearSelection();
            }
        },
        handleKeyDown(event) {
            if (event.key === 'Escape') {
                this.clearSelection();
            } else if (event.key === 'Enter') {
                this.$store.dispatch('addUserUiElementToCard', this.selectedProperties);
            }
            event.stopPropagation();
        },
        getClassForProperty(property) {
            return {
                'selected': this.isSelected(property),
                'required': property.required
            };
        },
        setDragImage(event) {
            const selectedCount = this.selectedProperties.length;
            if (selectedCount > 1) {
                const dragImage = document.createElement('div');
                dragImage.className = 'drag-image';
                dragImage.innerText = `${selectedCount} items`;
                document.body.appendChild(dragImage);

                event.dataTransfer.setDragImage(dragImage, dragImage.clientWidth / 2, dragImage.clientHeight / 2);

                setTimeout(() => {
                    document.body.removeChild(dragImage);
                }, 0);
            }
        },
        splitDisplayName(property) {
            return property.display_name ? property.display_name.split(': ') : [this.getLastItemInPath(property.property_path)];
        },
        async fetchUserApiPropertiesIfNeeded() {
            if (this.shouldFetchProperties()) {
                await this.fetchUserApiProperties();
            }
        },
        async fetchUserApiProperties() {
            if (!this.selectedApiService && !this.selectedUserApiResource) {
                return
            }
            const params = {
                connection_id: this.selectedApiService.connection_id,
                resource_id: this.selectedUserApiResource.id
            };

            try {
                const { data } = await axios.get(RESOURCES_PROPERTIES_URL, { params });
                this.$store.commit('setUserApiProperties', data);
            } catch (error) {
                console.error(error.response?.data || error);
            }
        },
        filterPropertiesNotInComponentHelper(purpose, properties) {
            const propsInComponent = this.getPropsInComponent(purpose);
            return properties.filter(item => !propsInComponent.includes(item.id) && this.isSearchMatch(item));
        },
        isSearchMatch(item) {
            return item.display_name.toLowerCase().includes(this.searchTerm.toLowerCase());
        },
        getPropsInComponent(purpose) {
            return this.propsAlreadyInComponent?.filter(item => item.purpose === purpose).map(item => item.property_id) || [];
        },
        shouldFetchProperties() {
            return this.selectedApiService?.connection_id === this.selectedUserApiResource?.connection_id;
        },
        updateSelectedProperties(property) {
            if (!this.isSelected(property)) {
                this.selectedProperties.push(property);
            }
        },
        selectRange(lastSelectedIndex, currentIndex) {
            const [start, end] = [lastSelectedIndex, currentIndex].sort((a, b) => a - b);
            const newSelection = this.propertiesNotInComponent.slice(start, end + 1);
            this.selectedProperties = [...new Set([...this.selectedProperties, ...newSelection])];
        },
        toggleSelection(property) {
            const index = this.selectedProperties.findIndex(p => p.id === property.id);
            if (index >= 0) {
                this.selectedProperties.splice(index, 1);
            } else {
                this.selectedProperties.push(property);
            }
        },
        clearSelection() {
            this.selectedProperties = [];
            this.lastSelectedProperty = null;
        },
        isSelected(property) {
            return this.selectedProperties.some(p => p.id === property.id);
        }
    },
    mounted() {
        window.addEventListener('click', this.handleClickOutside);
        window.addEventListener('keydown', this.handleKeyDown);
        window.addEventListener('dragover', this.handleDragOver);
        window.addEventListener('drop', this.handleDrop);
    },
    beforeUnmount() {
        window.removeEventListener('click', this.handleClickOutside);
        window.removeEventListener('keydown', this.handleKeyDown);
        window.removeEventListener('dragover', this.handleDragOver);
        window.removeEventListener('drop', this.handleDrop);
    }
};
</script>