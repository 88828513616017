import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import store from './store'
import logoutMixin from '@/mixins/logoutMixin.js';
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: Home,
      name: "Home",
      meta: { requiresAuth: true },
      // mixins: [logoutMixin]
    },
    {
      path: "/login",
      component: Login,
      name: "Login",
    }
  ],
});

export function handleError(error) {
  if (error.response.status === 401) {
    Cookies.remove('token');
    router.push({ name: 'Login' })
      // window.location.href = '/login';
  }
}

// Function to check token validity
function checkTokenValidity() {

  const token = Cookies.get('token');
  if (!token) {
    // Token is not present, redirect to login
    router.push({ name: 'Login' });
    return;
  }
  try {
    // Decode the token to access its payload
    const decodedToken = jwtDecode(token);

    // Check if the token has expired
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    if (decodedToken.exp && decodedToken.exp < currentTime) {
      // Token has expired, redirect to login
      logoutMixin.methods.handleLogout();
      return;
    }

     // Calculate the time left until token expiration in seconds
     const timeLeft = decodedToken.exp - currentTime;

     // Show an alert if there's 1 minute or less left until expiration
     if (timeLeft <= 60) {
       showAlert(timeLeft);
     }

  } catch (error) {
    // Token decoding failed, redirect to login
    logoutMixin.methods.handleLogout();
    return;
  }
}

// Check if the route requires authentication
router.beforeEach((to) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (Cookies.get('token') == null) {
      router.push({ name: 'Login' });
    }
  }
})

// Initial token check
checkTokenValidity();

// Set up a timer to periodically check token validity
setInterval(checkTokenValidity, 10000); // Check every 10 seconds

// Function to show an alert
function showAlert(timeLeft) {
    store.commit('addNotification', { 
      componentId: 0,
      type: 'error',
      title: `Session expiring`,
      message: `You will be auto-logged out in ${timeLeft} sec due to inactivity.`
  });
}


// router.beforeEach((to, from, next) => {
//   const isLoggedIn = Cookies.get('token');
//   const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

//   if (requiresAuth && !isLoggedIn) {
//     logoutMixin.methods.handleLogout();
//     next({ name: 'Login' });
//   } else {
//     next();

//   }
// });

// router.afterEach((to, from) => {
//   console.log('After: Navigated from:', from)
//   console.log('After: Navigated to:', to)

// })
