<template>
    <div class="right-sidebar__section-block">
        <div class="right-sidebar__section-block--name">{{ title }}</div>
        <div class="draggable-attribute--container" @click.stop>
            <template v-if="properties.length > 0">
                <li v-for="property in properties" 
                    :key="property.id" 
                    draggable="true" 
                    :data-index="property.id"
                    @dragstart="handleDragStart(property, $event)" 
                    @dblclick="handleDoubleClick(property)"
                    @click="handleClick(property, $event)"
                    :class="['draggable-attribute__item', getClassForProperty(property)]">
                    <span class="draggable-attribute__item--title">
                        <span v-for="(item, index) in splitDisplayName(property)" :key="index">
                            {{ item }}<i v-show="index < splitDisplayName(property)?.length - 1" class="icon icon-arrow-expand"></i>
                        </span>
                    </span>
                    <!-- <span v-show="property.description" class="draggable-attribute__item--line-2"></span> -->
                    <!-- <span v-show="property.example_value" class="draggable-attribute__item--line-3"></span> -->
                </li>
            </template>
            <template v-else>
                <div class="right-sidebar__empty-state">
                    <span v-if="searchTerm">No matching results.</span>
                    <span v-else-if="propertiesInResource?.length === 0">No properties available.</span>
                    <span v-else>{{ emptyMessage }}</span>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SidebarSection',
    props: {
        properties: Array,
        title: String,
        searchTerm: String,
        emptyMessage: String,
        selectedProperties: Array,
        propertiesInResource: Array,
    },
    methods: {
        handleDragStart(property, event) {
            this.$emit('dragStart', property, event);
        },
        handleClick(property, event) {
            this.$emit('click', property, event);
        },
        handleDoubleClick(property) {
            this.$emit('doubleClick', property);
        },
        getClassForProperty(property) {
            const isSelected = this.selectedProperties.some(p => p.id === property.id);
            const classes = {
                'selected': isSelected,
                'required': property.required,
            };
            return classes;
        },
        splitDisplayName(property) {
            return property.display_name ? property.display_name.split(': ') : [this.getLastItemInPath(property.property_path)];
        },
        getLastItemInPath(path) {
            const pathArray = path.split(',');
            return pathArray[pathArray.length - 1];
        }
    }
};
</script>
