<template>
    <ul class="contextual-menu js-contextual-menu">
        <li @click="openModalAddRenamePage" class="contextual-menu__item">
            <i class="icon icon-rename"></i>
            <span>Rename</span>
        </li>
        <li @click="openModalDeletePage" class="contextual-menu__item">
            <i class="icon icon-trash icon--danger"></i>
            <span class="contextual-menu__label--delete">Delete</span>
        </li>
    </ul>
</template>

<script>

export default {
    name: 'NavigationContextMenu',
    props: {
        pageId: {
            type: Number,
            required: true
        }
    },
    methods: {
        handleClickOutside(event) {
            if (!event.target.closest('#contextual-menu-container')) {
                this.$store.commit('showContextMenu', false)
            }
        },
        openModalDeletePage() {
            this.$store.commit('showModalDeletePage', true)  
            this.$store.commit('modalTargetPageId', this.pageId) 
            this.$store.commit('showContextMenu', false)   
        },
        handleDelete() {
            console.log(this.pageId)
        },
        openModalAddRenamePage() {
            this.$store.commit('showModalAddRenamePage', true)  
            this.$store.commit('modalTargetPageId', this.pageId) 
            this.$store.commit('showContextMenu', false) 
        },
    },
    mounted() {
        this.handleClickOutsideWrapper = event => this.handleClickOutside(event);
        window.addEventListener("click", this.handleClickOutsideWrapper);
    },
    beforeUnmount() {
        window.removeEventListener("click", this.handleClickOutsideWrapper);
    }
};

</script>

<style scoped>
.js-contextual-menu {
    display: flex;
}
</style>