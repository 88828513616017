export const isDevelopment = process.env.NODE_ENV === 'production' ? false : true;

export const uiComponents = [
    {
        name: 'Data table',
        type: 'data-table',
        icon: 'icon-data-table'
    },
    {
        name: 'Form',
        type: 'form-item',
        icon: 'icon-form'
    },
    {
        name: 'Single item view',
        type: 'view-entry',
        icon: 'icon-single-item-view'
    }
];