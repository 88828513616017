<template>
    <div class="container-login">
        <div>
            <div class="card login-form">

                <div class="login-form__header">
                    <img class="logo" src="@/assets/img/logo.svg" alt="Magestic logo">
                </div>

                <form>
                    <div class="login-form__content">

                        <h1 class="login-form__title">Sign in</h1>

                        <div class="login-form__input-fields">

                            <div v-bind:class="{ 'error': validEmail == false }"
                                class="textfield textfield-large textfield-filled">
                                <div class="textfield-header">
                                    <label for="select">Email</label>
                                </div>
                                <input type="text" v-model.trim="email" @change="validateEmail()"
                                    placeholder="john.doe@mail.com">
                                <div v-if="validEmail == false" class="error-msg">Enter a valid email address</div>
                            </div>

                            <div v-bind:class="{ 'error': validPassword == false }"
                                class="textfield textfield-large textfield-filled">
                                <div class="textfield-header">
                                    <label for="select">Password</label>
                                </div>
                                <input type="password" v-model.trim="password" @change="validatePassword()">
                                <div v-if="validPassword == false" class="error-msg">Enter the password</div>
                            </div>

                        </div>

                        <div v-if="showAlert" class="alert alert-error">
                            <span class="alert-message">{{ apiErrorMessage }}</span>
                            <button @click="closeAlert()" type="submit" class="btn btn-toggle">
                                <i class="icon icon-close"></i>
                            </button>
                        </div>
                        <div class="login-form__button">
                            <button 
                                class="btn btn-large btn-contained-primary g-recaptcha"
                                :class="{ 'btn-contained-primary--disabled': loading }"
                                @click="setLoadingStatus()"
                                :data-sitekey="recaptchaSiteKey" 
                                data-callback="onsubmit"
                                >
                                <span v-if="loading" class="button-loader"></span>
                                <span v-else>Sign in</span>
                            </button>
                        </div>
                    </div>
                </form>
                
                <div class="login-form__divider">
                    <b></b>
                    <span>or</span>
                    <b></b>
                </div>
                <div class="google-button__container">
                    <div class="google-button__wrapper">
                        <!-- <div class="google-button__custom" @click="handleGoogleLoginClick">Sign in with Google</div> -->
                    <GoogleLogin id="GoogleLogin" ref="googleLoginRef" :callback="googleLoginCallback" />
                        <span class="loader-dots"><span></span><span></span><span></span></span>
                    </div>
                </div>

                <div class="login-form__signup">
                    Want an account? <a href="mailto:hello@magestic.app">Get in touch</a>.
                </div>

                <div class="recaptcha-disclaimer">
                     This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import isEmail from 'validator/lib/isEmail'
import escape from 'validator/lib/escape'
import axios from 'axios'

export default {
    name: 'Login',
    data() {
        return {
            email: '',
            password: '',
            validEmail: undefined,
            validPassword: undefined,
            showAlert: false,
            apiErrorMessage: '',
            loading: false,
            apiCallInProgress: false,
            idToken: null,
            recaptchaToken: null,
            recaptchaSiteKey: process.env.VUE_APP_RECAPTCHA_KEY
        }
    },
    methods: {
        setLoadingStatus() {
            this.loading = true;
        },
        handleClickOutside(event) {
            // Check if the clicked element is not part of the .login-form
            if (!event.target.closest('.login-form') && !this.apiCallInProgress) {
                this.loading = false;
            }
        },
        loadRecaptchaScript() {
            // Load the reCAPTCHA script dynamically
            const script = document.createElement('script');
            script.src = 'https://www.google.com/recaptcha/api.js';
            script.async = true;
            script.defer = true;

            script.onerror = () => {
                console.error('Error loading reCAPTCHA script');
                this.showAlert = true;
                this.apiErrorMessage = 'Error loading reCAPTCHA. Try again later.';
            };

            document.head.appendChild(script);
            
            // Define the global onsubmit function
            window.onsubmit = (token) => {
                this.recaptchaToken = token;
                this.handleSubmit();
            };
        },
        googleLoginCallback(response) {
        // console.log('Logged in successfully:', response);
        if (response.credential) {
            this.idToken = response.credential;
            this.loginApiCall('google');
        }
        },
        validateEmail() {
            this.validEmail = isEmail(this.email);
            return this.validEmail;
        },
        validatePassword() {
            this.password = escape(this.password);
            this.validPassword = this.password.length > 0;
            return this.validPassword;
        },
        closeAlert() {
            this.showAlert = false
        },
        async handleSubmit() {
            if (!this.validEmail) { 
                this.validEmail = false; 
            }
            if (!this.validPassword) { 
                this.validPassword = false; 
            }
            if (!this.validateEmail() || !this.validatePassword()) {
                return;
            }
            if(!this.recaptchaToken) {
                this.showAlert = true;
                this.apiErrorMessage = 'reCAPTCHA token not found or invalid. Refresh and try again.';
                this.loading = false;
                return;
            }

            try {
                await this.loginApiCall('password');
            } catch (error) {
                console.error('Error during login:', error);
                this.showAlert = true;
                this.apiErrorMessage = 'Login failed. Try again.';
                this.loading = false;
            }
        },
        async loginApiCall(authType) {  
            this.apiCallInProgress = true; 
            this.loading = true;        

            try {
                let payload = {};

                if (authType === 'google') {
                    payload = {
                        auth_type: 'google',
                        id_token: this.idToken,
                    };
                } else if (authType === 'password') {
                    payload = {
                        auth_type: 'password',
                        username: this.email,
                        password: this.password,
                        recaptcha_token: this.recaptchaToken,
                    };
                }
                let response = await axios.post('/login.php', payload, {
                    timeout: 5000
                });

                if (response.status === 200 && response.data.status === 'success') {
                    this.showAlert = false
                    this.$router.push({ name: 'Home' })
                }

            } catch (error) {
                // console.error(error.response);
                if (error.response.data.status === 'error') {
                    this.showAlert = true
                    this.apiErrorMessage = 'Login failed. Verify your username and password.';
                    // console.error('Error during login:', error.response.data.message);
                    }
                // Reset the reCAPTCHA token if grecaptcha is available
                if (window.grecaptcha) {
                        this.recaptchaToken = null;
                        window.grecaptcha.reset();
                } else {
                    console.error('grecaptcha is not available');
                }    
            } finally {
                this.apiCallInProgress = false;
                this.loading = false;
                
            }
        },
    },
    mounted() {
        this.loadRecaptchaScript();
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    }
}
</script>

<style scoped>
.error-msg {
    display: block;
}


.button-loader {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #fff;
    border-radius: 50%;
    border-top-color: transparent;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>