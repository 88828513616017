<template>
    <div class="right-sidebar__section">
        <div class="right-sidebar__section-header">Components</div>
        <div class="right-sidebar__section-item">
            <ul class="draggable-components">
                <li v-for="(element, index) in uiComponents" 
                    :key="element.name" 
                    draggable="true" 
                    :data-index="index"
                    @dragstart="handleDragStart"
                    @dblclick="handleDoubleClick(index)"
                    class="draggable-components__item"
                >
                    <i :class="element.icon" class="icon"></i>
                    <span>{{ element.name }}</span>
                    <i class="icon icon-drag"></i>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { uiComponents } from '@/utils/globalVariables.js';

export default {
    name: 'Components',
    data() {
        return {
            uiComponents
        }
    },
    computed: {
        ...mapState(['userUiComponents', 'selectedUiComponentId'])
    },
    methods: {
        handleDragStart(event) {
            event.dataTransfer.setData('text/plain', event.target.dataset.index);
        },
        handleDoubleClick(index) {
            this.$store.dispatch('addUiComponentToPage', index);
        },

    }

};

</script>
