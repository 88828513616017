<template>
  <div class="main-container" :class="{ 'js-edit-mode': $store.state.editMode }">
    <Header />
    <div class="main-content">
      <Navigation />
      <PageContent />
      <RightSidebar v-if="$store.state.editMode" />
    </div>
  </div>
</template>

<script>

import Header from '@/components/Header/Header.vue';
import Navigation from '@/components/Navigation/Navigation.vue';
import PageContent from '@/components/PageContent/PageContent.vue';
import RightSidebar from '@/components/RightSidebar/RightSidebar.vue';
import logoutMixin from '@/mixins/logoutMixin.js';
import { mapState } from 'vuex'


export default {
  name: 'Home',
  mixins: [logoutMixin],
  data() {
    return {
      response: ''
    }
  },
  watch: {
    selectedUiComponentId(newValue) {
      if (newValue === null) {
        this.$store.commit('setSelectedUiElement', null);
      }
    }
  },
  components: {
    Header,
    Navigation,
    PageContent,
    RightSidebar
  },
  ...mapState([
            'selectedUiComponentId',
  ]),
  mounted() {
    this.$store.dispatch('fetchUser'); // load user data
    this.$store.dispatch('fetchPages'); // load pages in navigation panel
  }
}

</script>

<style>

</style>
