import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'
import './axios'
import store from './store'
import vue3GoogleLogin from 'vue3-google-login'

// Import global variables
import { isDevelopment } from './utils/globalVariables.js';

// Load sensitive data from environment variables
const googleClientId = process.env.VUE_APP_GOOGLE_CLIENT_ID;

const app = createApp(App)
  .use(router)
  .use(store)
  .use(vue3GoogleLogin, {
    clientId: googleClientId,
    buttonConfig: {
      color: 'white',
      width: 263,
      type: 'standard',
      size: 'medium',
      logo_alignment: 'center',
      text: 'signin_with',
      theme: 'outline',
      auto_select: true,
    },
  });

// Register global properties
app.config.globalProperties.$isDevelopment = isDevelopment;

// Enable Vue Devtools in development
if (isDevelopment) {
  app.config.devtools = true;
}

app.mount('#app');
