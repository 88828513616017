
import axios from 'axios'
// import router from './router';
// import { mapState } from 'vuex'
import Cookies from 'js-cookie';
import { handleError } from './router'; 

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.response.use(response => {
    let token = response.headers.authorization;
    if (token) {
        let cleanToken = token.replace('Bearer ', '');
        
        Cookies.set('token', cleanToken, { secure: true, sameSite: 'strict' });

        axios.defaults.headers.common['Authorization'] = `Bearer ${Cookies.get('token')}`;
        token = null;
    }
    return response;
  }, error => {
    handleError(error);
    return Promise.reject(error);
  }
  );

if (Cookies.get('token')) {
axios.defaults.headers.common['Authorization'] = `Bearer ${Cookies.get('token')}`;
  }

export default axios;




