<template>
    <!-- <pre>selectedUiComponent: {{ selectedUiComponent }}</pre>
    <pre v-if="resourceOfSelectedComponent">{{ resourceOfSelectedComponent }}</pre>
    <pre v-if="selectedUserApiResource">{{ selectedUserApiResource }}</pre> -->
    <!-- <pre>userApiResourcesFiltered: {{ userApiResourcesFiltered }}</pre> -->
    <!-- <pre v-if="selectedApiService">{{ selectedApiService }}</pre> -->
    <!-- <pre>{{ userApiResources }}</pre> -->
    <!-- <pre>userUiElements[selectedUiComponentId]: {{ userUiElements[selectedUiComponentId] }}</pre> -->
    
    <div class="right-sidebar__section-item"> 
        <div class="right-sidebar__section-subtitle">Resources</div>

        <div class="dropdown-wrapper">
            <div :class="'dropdown__select-box' 
                        + (componentHasElements || !userApiResourcesFiltered ? ' dropdown__select-box--disabled' : '')
                        + (isDropdownActive ? ' js-btn-active' : '')" 
                        @click="toggleDropdown"
            >

                <ul v-if="userApiResourcesFiltered">
                    <li v-if="selectedUserApiResource?.name" class="dropdown__item--title">
                        {{ selectedUserApiResource?.name }}
                    </li>
                    <li class="dropdown__item--line-2">
                        <!-- [<span class="capitalize">{{ selectedUserApiResource?.method }}</span>] -->
                        {{ selectedUserApiResource?.description }}
                    </li>
                    <!-- <li class="dropdown__item--line-3">/{{ selectedUserApiResource?.path }}</li> -->
                </ul>
                <ul v-else>
                    <li class="dropdown__item--title">
                        No resources available
                    </li>
                </ul>
                <div><i class="icon icon-chevron-down"></i></div>
            </div>
            <ul 
                v-if="userApiResourcesFiltered" 
                ref="dropdownList" 
                class="dropdown__list" 
                :class="{ 'js-show-dropdown': isDropdownActive }"
            >
                <li 
                    v-for="resource in userApiResourcesFiltered" 
                    :key="resource.id" 
                    :class="dropdownItemClass(resource)"
                    @click="setSelectedUserApiResource(resource)"
                >

                    <ul>
                        <li v-if="resource.name" class="dropdown__item--title">{{ resource.name }}</li>
                        <li class="dropdown__item--line-2">
                            <!-- [<span class="capitalize">{{ resource.method }}</span>] -->
                            {{ resource.description }}
                        </li>
                        <!-- <li class="dropdown__item--line-3">/{{ resource.path }}</li> -->
                    </ul>
                </li>
            </ul>
        </div>

    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'UserApiResources',
    data() {
        return {
            resourceOfSelectedComponent: null
        }
    },
    watch: {
        selectedApiService: {
            immediate: false,
            async handler(newVal) {
                if (newVal) {
                    await this.$store.dispatch('fetchUserApiResources', { connectionId: this.selectedApiService.connection_id});
                    this.handleComponentTypeChange();
                }
            }
        },
        selectedUiComponentId: {
            immediate: true,
            handler(newValue, oldValue) {
                if (newValue && newValue !== oldValue) {
                    this.loadAllDependecies()
                    this.handleComponentTypeChange()
                }
            }
        },
        selectedResourceIdBySelectedComponent: {
            immediate: true,
            deep: true,
            handler(newValue) {
                if (newValue) {
                    this.setSelectedUserApiResource(this.getResourceByResourceId(newValue));
                }
            }
        },

    },
    computed: {
        ...mapState([
            'userApiResources',
            'userApiResourcesFiltered',
            'selectedApiService', 
            'selectedUserApiResource', 
            'selectedUiComponentId', 
            'userUiComponents', 
            'userUiElements',
            'dropdowns'
        ]),
        isDropdownActive() {
            return this.dropdowns[this.$options.name]
        },
        componentType() {
            if (!this.selectedUiComponentId) {
            return null;
            }
            const matchingObject = this.userUiComponents.find(obj => obj.id === this.selectedUiComponentId);
            if (matchingObject) {
            return matchingObject.type;
            } else {
            return null;
            }
        },
        resourcesFilteredByType() {
            const resources = this.$store.state.userApiResources;
            if (!resources || resources.length === 0 || !this.componentType) {
                return null;
            }
            let response = null;
            switch (this.componentType) {
                case 'data-table':
                response = resources.filter((obj) => obj.method === 'get' && obj.type === 'list');
                break;
                case 'form-item':
                response =  resources.filter((obj) => ['post', 'put', 'patch'].includes(obj.method));
                break;
                case 'view-entry':
                response =  resources.filter((obj) => obj.method === 'get' && obj.type === 'single');
                break;
            }
            return response;
        },
        componentHasElements() {
            return this.userUiElements[this.selectedUiComponentId]?.length > 0
        },
        selectedUiComponent() {
            return this.userUiComponents?.find(obj => obj.id === this.selectedUiComponentId)
        },
        selectedResourceIdBySelectedComponent() {
            return this.selectedUiComponent?.resource_id;
        },
        
    },
    methods: {
        filterResourcesByApiService(resources) {
            if (!resources || resources.length === 0 || !this.selectedApiService) {
                return null;
            }
            return resources?.filter(resource => resource.connection_id === this.selectedApiService.connection_id)
        },
        async getResourceOfSelectedComponent() {
            await this.$nextTick()
            if(!this.userUiElements 
               || !this.userUiElements[this.selectedUiComponentId] 
               || !this.userUiElements[this.selectedUiComponentId][0] 
               || !this.userApiResourcesFiltered 
               || this.userApiResourcesFiltered.length === 0
            ) {
                return null
            }
            const resourceId = this.userUiElements[this.selectedUiComponentId][0]['resource_id']
            return this.resourceOfSelectedComponent = this.userApiResourcesFiltered.find(resource => resource.id == resourceId) || null
        },
        getResourceByResourceId(resourceId) {
            return this.userApiResources.find(resource => resource.id === resourceId);
        },
        dropdownItemClass(resource) {
            if(!this.resourceOfSelectedComponent) {
                return `dropdown__item `;
            } else {
                const isSelected = this.isResourceSelected(resource.id)
                const isDisabled = this.resourceOfSelectedComponent 
                                   && resource.id !== this.resourceOfSelectedComponent.id 
                                   && this.userUiElements[this.selectedUiComponentId]?.length > 0;
                return `dropdown__item ${isSelected ? 'dropdown__item--selected' : ''} ${isDisabled ? 'dropdown__item--disabled' : ''}`
            }
        }, 
        isResourceSelected(id) {
            return this.selectedUserApiResource?.id === id
        },
        closeAllDropdowns() {
            for (const key in this.dropdowns) {
                if (key !== this.$options.name) {
                    this.$store.commit('setDropdownState', { id: key, value: false })
                }
            }
        },
        toggleDropdown(event) {
            event.stopPropagation()
            this.closeAllDropdowns()
            const state = this.isDropdownActive
            this.$store.commit('setDropdownState', { id: this.$options.name, value: !state })
            if (!state) {
                document.addEventListener('click', this.closeDropdown)
            }
            if (this.userApiResources.length>0) {
                this.$nextTick(() => {
                const selectedElement = this.$refs.dropdownList.querySelector('.dropdown__item--selected')
                if (selectedElement) {
                selectedElement.scrollIntoView({ block: 'nearest'})
                }
            })
            }
        },
        closeDropdown() {
            this.$store.commit('setDropdownState', { id: this.$options.name, value: false })
            document.removeEventListener('click', this.closeDropdown)
        },
        setSelectedUserApiResource(resource) {
            this.$store.commit('setSelectedUserApiResource', resource);
        },

        handleComponentTypeChange() {
            const resources = this.$store.state.userApiResources;
            if (resources?.length) {
                    if (this.componentType) {
                        let filteredResources = this.resourcesFilteredByType;
                        filteredResources = this.filterResourcesByApiService(filteredResources);
                        this.$store.commit('setUserApiResourcesFiltered', filteredResources)
                    }
                    if (this.userApiResourcesFiltered?.length && !this.selectedResourceIdBySelectedComponent) {
                        this.setSelectedUserApiResource(this.$store.state.userApiResourcesFiltered[0])
                    }
                }
        },
        async loadAllDependecies() {
            const allDataLoaded = await Promise.all([
                this.selectedApiService,
                this.selectedUiComponentId,
                this.userApiResources,
                this.getResourceOfSelectedComponent(),
                this.userUiElements
            ].map(data => {
                if (Array.isArray(data)) {
                    return data.length > 0;
                } else {
                    return Boolean(data);
                }
            }));

            if (allDataLoaded.every(Boolean)) {
                if (this.resourceOfSelectedComponent && this.componentHasElements) {
                    this.setSelectedUserApiResource(this.resourceOfSelectedComponent);
                }
            }
        }
    },
    updated() {
        this.loadAllDependecies()
    },

}

</script>

<style scoped>
.capitalize {
    text-transform: uppercase;
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}
</style>